import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";

if (environment.production) {
    enableProdMode();
    Sentry.init({
        dsn: "https://94fef15c27629a0dda03bc219ce13e2d@sentry.fpf.br/5",
        integrations: [
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
            }),
            Sentry.browserTracingIntegration()
        ],
        tracePropagationTargets: [
            "https://api.bit.fpf.br/api",
        ],
        replaysSessionSampleRate: 0.2, // Sample rate at 10%
        replaysOnErrorSampleRate: 1.0, // 100% on error
    });
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
